import { Avatar, Box, Menu, Stack, Typography } from '@mui/material';
import { Button } from '../../shared';
import images from '../../../config/images';
import storage from '../../../utils/storageUtils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React from 'react';
import useStyles from '../Header/styles';
import { useHistory } from 'react-router-dom';

const AddIcon = ({ isLandingPage, hasTotal, onClick }) => {
  if (hasTotal) {
    return isLandingPage ? (
      <img
        src={images.icons.garyAddIcon}
        alt="addIcon"
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      />
    ) : (
      <AddCircleOutlineIcon
        width="24px"
        height="24px"
        sx={{ color: '#00000054', cursor: 'pointer' }}
        onClick={onClick}
      />
    );
  }

  return <img src={images.icons.AddAiIcon} alt="addIcon" onClick={onClick} />;
};

const ContentRow = ({ icon, title, count, isLandingPage, onAddClick }) => {
  const classes = useStyles({ isLandingPage });
  return (
    <Box display="flex" justifyContent="space-between" py="8px">
      <Box display="flex" gap="8px">
        <img src={icon} alt={title} />
        <Box className={classes.personaliseContentTitle}>{title}</Box>
        {count > 0 && <Box className={classes.personaliseContentTitleNumber}>{count}</Box>}
      </Box>
      <AddIcon isLandingPage={isLandingPage} hasTotal={count > 0} onClick={onAddClick} />
    </Box>
  );
};

const AppMenu = ({
  userNameMenuElement,
  userNameId,
  handleMenuClose,
  hasLmsAccess,
  hasOutreachAccess,
  showUpgradePlan,
  user,
  handleLogout,
  isLandingPage = false,
  setIsModalOpen,
  ...props
}) => {
  const classes = useStyles({ isLandingPage });
  const history = useHistory();
  const name = user ? `${user.fname || ''}` : '';
  const { generateToneOfVoiceWithAI = false, hasICPAccess = false } =
    user?.tenants[0]?.featureAccess || {};

  const handleIcpClick = () => {
    history.push('/profile/icp');
    handleMenuClose();
  };

  const handleVoiceClick = () => {
    history.push('/profile/voice');
    handleMenuClose();
  };
  return (
    <Menu
      anchorEl={userNameMenuElement}
      id={userNameId}
      keepMounted
      open={Boolean(userNameMenuElement)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: classes.menuPaper,
      }}
      sx={{
        '& .MuiList-root': { padding: '0px !important' },
        position: isLandingPage && 'absolute',
        zIndex: isLandingPage && 12000,
      }}
    >
      <Box sx={{ padding: '24px 24px 16px 24px' }}>
        <Box display="flex" gap="16px" alignItems="center">
          <Avatar className={classes.menuAvatar}>{name.charAt(0)}</Avatar>
          <Box sx={{ width: '224px' }}>
            <Stack gap={'8px'}>
              <Box display="flex" gap="8px" alignItems="center">
                <Typography variant="h6" className={classes.menuTypography}>
                  Hi, {name}!
                </Typography>
                {!showUpgradePlan && (
                  <Button
                    onClick={() => {
                      handleMenuClose();
                      if (user?.acl?.accessLevel?.toLowerCase() === 'admin') {
                        history.push('/admin/billing/subscriptions');
                      } else {
                        setIsModalOpen(true);
                      }
                    }}
                    className={classes.freeTrialButton}
                  >
                    Free Trial
                  </Button>
                )}
              </Box>

              <Typography variant="body2" className={classes.menuEmailWrapper}>
                {user?.email}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        sx={{ padding: '16px', paddingTop: '0' }}
      >
        <Box className={classes.exploreWrapper}>
          <Box className={classes.exploreTitle}>Explore SuperReach</Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto auto auto',
            }}
          >
            {hasOutreachAccess && (
              <Box
                class={classes.iconStyle}
                onClick={() => {
                  window.open('/');
                }}
              >
                <img
                  src={images.icons.outreach}
                  alt="outreach icon"
                  style={{ margin: 'auto' }}
                  height="40px"
                  width="40px"
                />
                Outreach
              </Box>
            )}
            {hasLmsAccess && (
              <Box
                class={classes.iconStyle}
                onClick={() => {
                  window.open(process.env.REACT_APP_LMS_URL, '_blank');
                }}
              >
                <img
                  src={images.icons.learning}
                  alt="learning icon"
                  style={{ margin: 'auto' }}
                  height="40px"
                  width="40px"
                />
                Learning
              </Box>
            )}

            {user?.acl?.accessLevel === 'Admin' && (
              <Box
                onClick={() => {
                  const route = history.location.pathname;
                  if (route && route !== '') {
                    storage.set('REFERRAL_ROUTE', route);
                  }
                  window.open('/admin/users', '_blank');
                }}
                class={classes.iconStyle}
              >
                <img
                  src={images.icons.admin}
                  alt="Admin icon"
                  style={{ margin: 'auto' }}
                  height="40px"
                  width="40px"
                />
                Admin
              </Box>
            )}
          </Box>
        </Box>
        {(generateToneOfVoiceWithAI || hasICPAccess) && (
          <Box className={classes.exploreWrapper}>
            <Box className={classes.exploreTitle}>Personalise SuperReach</Box>
            {hasICPAccess && (
              <ContentRow
                icon={props.icp.total > 0 ? images.icons.GrayICPIcon : images.icons.ICPIcon}
                title="Add an Ideal Customer Profile"
                count={props.icp.total}
                isLandingPage={isLandingPage}
                onAddClick={handleIcpClick}
              />
            )}
            {generateToneOfVoiceWithAI && (
              <ContentRow
                icon={props.voices.total > 0 ? images.icons.grayTovIcon : images.icons.tovIcon}
                title="Add a Tone of Voice"
                count={props.voices.total}
                isLandingPage={isLandingPage}
                onAddClick={handleVoiceClick}
              />
            )}
          </Box>
        )}
      </Box>
      {!showUpgradePlan && (
        <Box
          sx={{
            margin: '0 0 24px 0',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            alignItems: 'flex-end',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              padding: '8px 0 8px 23px',
              margin: '0',
              height: '80px',
              boxSizing: 'border-box',
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Trillion...</Typography>
            <div
              style={{
                background: 'linear-gradient(to right, #3F9FFE, #CF0175, #F0A818)',
                borderRadius: '999px',
                marginTop: '8px',
                padding: '0',
              }}
            >
              <Button
                sx={{
                  borderRadius: '999px',
                  background: 'white',
                  fontSize: '12px',
                  fontWeight: '500',
                  padding: '0 8px',
                  margin: '1px',
                }}
                onClick={() => {
                  handleMenuClose();
                  if (user?.acl?.accessLevel?.toLowerCase() === 'admin') {
                    history.push('/admin/billing/subscriptions');
                  } else {
                    setIsModalOpen(true);
                  }
                }}
              >
                Upgrade
              </Button>
            </div>
          </Box>
          <img src={images.icons.multiIcons} alt="Multi icons" />
          <Box padding="0 5px">
            <img
              src={images.icons.close}
              style={{ position: 'absolute', top: '4px', right: '4px' }}
              alt="close"
            />
          </Box>
        </Box>
      )}
      <Box padding="0px 24px 24px 24px" display="flex" flexDirection="column" gap="8px">
        <Box
          gap="8px"
          sx={{ cursor: 'pointer' }}
          py="8px"
          display="flex"
          onClick={() => {
            history.push('/profile/details');
            handleMenuClose();
          }}
        >
          <Box display="flex" alignItems="center" gap="8px">
            {isLandingPage ? (
              <img src={images.icons.darkSetting} alt="sign out" />
            ) : (
              <SettingsOutlinedIcon sx={{ color: '#00000082' }} />
            )}

            <Typography className={classes.menuEmailWrapper}>My Settings</Typography>
          </Box>
        </Box>
        <Box gap="8px" py="8px" display="flex" sx={{ cursor: 'pointer' }} onClick={handleLogout}>
          <Box display="flex" alignItems="center" gap="8px">
            <img
              src={isLandingPage ? images.icons.darkSignOut : images.icons.signOut}
              alt="sign out"
            />
            <Typography className={classes.menuEmailWrapper}>Sign out of all accounts</Typography>
          </Box>
        </Box>
      </Box>
    </Menu>
  );
};

export default AppMenu;
