import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, IconButton, Stack } from '@mui/material';
import { Button, Form, Modal } from 'src/components/shared';
import { FieldArray } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

const AddNewUserDialog = ({ open, onClose, sectionKey, values, defaultOveride }) => {
  const [users, setUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);
  const cachedUsers = useMemo(() => users, [users]);
  const [filteredOverrides, setFilteredOverrides] = useState([]);

  useEffect(() => {
    setFilteredOverrides(
      values[sectionKey]?.overrides?.filter((override) => override.action !== 'remove') || [],
    );
  }, [values, sectionKey]);

  const handleUserLookup = async (val) => {
    if (!cachedUsers.length) {
      const fetchedUsers = await fetchUsersLookup(val);
      setUsers(fetchedUsers);
      return fetchedUsers;
    }
    return cachedUsers;
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    const currentOverrides =
      filteredOverrides
        ?.filter((override) => override.user && override.userLimit)
        ?.map((override) => {
          setRemovedUsers((prev) => prev.filter((removed) => removed.user.id !== override.user.id));
          return {
            ...override,
            action: override.action || 'add',
          };
        }) || [];
    const allOverrides = [...currentOverrides, ...removedUsers];
    setFilteredOverrides((prev) => prev.filter((override) => override.user && override.userLimit));
    onClose(allOverrides);
  };

  return (
    <FieldArray
      enableReinitialize={true}
      name={`${sectionKey}.overrides`}
      render={(arrayHelpers) => (
        <Modal
          PaperProps={{
            sx: {
              borderRadius: '10px',
            },
          }}
          titleStyle={{
            mt: 2,
          }}
          open={Boolean(open)}
          onClose={handleClose}
          draggable={false}
          title="Add Custom User Limits - Data and Email"
          showCloseIcon
          maxWidth="sm"
          renderActions={() => (
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              sx={{ px: 2, pb: 2 }}
            >
              <Button
                color="secondary"
                onClick={() => {
                  arrayHelpers.push({
                    ...defaultOveride,
                    action: 'add',
                  });
                }}
                sx={{
                  transition: 'all 0.3s ease',
                }}
                startIcon={<AddIcon sx={{ marginRight: '6px', color: '#1976D2' }} />}
              >
                Add another user
              </Button>
              <Button variant="contained" color="secondary" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          )}
        >
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              transition: 'all 0.3s ease',
            }}
          >
            {filteredOverrides.map((override, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  '&:hover .remove-button': {
                    opacity: 1,
                  },
                }}
              >
                <Box sx={{ flex: 1, maxWidth: '40%' }}>
                  {index === 0 && (
                    <Typography variant="body2" color="textPrimary">
                      User
                    </Typography>
                  )}
                  <Form.Field.AutoComplete
                    remoteMethod={handleUserLookup}
                    name={`${sectionKey}.overrides[${index}].user`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    optLabel="name"
                    optValue="id"
                    options={cachedUsers}
                  />
                </Box>
                <Box sx={{ flex: 1, maxWidth: '40%' }}>
                  {index === 0 && (
                    <Typography variant="body2" color="textPrimary">
                      User Limit (Per Month)
                    </Typography>
                  )}
                  <Form.Field.Input
                    name={`${sectionKey}.overrides[${index}].userLimit`}
                    type="number"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <Box mr={0.5}>
                          <Typography color="textSecondary">$</Typography>
                        </Box>
                      ),
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                    }}
                  />
                </Box>
                {values[sectionKey].overrides.length > 1 && (
                  <IconButton
                    className="remove-button"
                    onClick={() => {
                      const removedUser = {
                        ...values[sectionKey].overrides[index],
                        action: 'remove',
                        userLimit: 0,
                      };
                      setRemovedUsers((prev) => [...prev, removedUser]);
                      arrayHelpers.remove(index);
                    }}
                    sx={{
                      color: 'red',
                      opacity: 0,
                      mt: 2,
                      transition: 'opacity 0.3s ease',
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Modal>
      )}
    />
  );
};

export default AddNewUserDialog;
