import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { CustomGatedFooterContent, CustomGatedMainContent } from '../styles';
import { useGuideModalStyles } from './styles';

import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/shared';
import Icon from 'src/components/lms/Icon';
import GatedModal from '../GatedModal';
import images from 'src/config/images';
import { putOnboardingStatus, setOutreachBoarding } from 'src/modules/app/actions/appActions';
import { updateUser } from 'src/modules/auth/api/authApis';
import { setCurrentUser } from 'src/modules/auth/actions/authActions';
import cookieUtils from 'src/utils/cookieUtils';

const guideStepList = [
  {
    key: 'automateOutreach',
    title: 'Automate Outreach, Stay Human',
    description:
      'Build multi-channel campaigns that keep you top-of-mind without losing that personal touch.',
  },
  {
    key: 'prospects',
    title: 'Add Prospects in a Click',
    description:
      'Save time by adding prospects directly to your sequences with minimal input, right from your browser.',
  },
  {
    key: 'dataClean',
    title: 'Keep Your Data Clean',
    description:
      'Ensure accurate contact details with automated validation and enhanced lead enrichment.',
  },
  {
    key: 'winningMessages',
    title: 'Create Winning Messages Instantly',
    description:
      'Let AI craft tailored emails and social media messages designed to engage your Ideal Customer Profile.',
  },
  {
    key: 'personaliseMessages',
    title: 'Personalise Every Message',
    description:
      'Match your outreach to your brand or audience with flexible tone-of-voice settings.',
  },
  {
    key: 'learning',
    heading: 'Learning',
    icon: 'itemplay',
    title: 'On Demand Learning and AI Coaching',
    description:
      'Get unlimited access to hundreds of sales and recruitment training sessions and personalised AI coaches to fast-track your learning goals!',
  },
  {
    key: 'rightProspects',
    title: 'Target the Right Prospects',
    description:
      'Focus your efforts on the most valuable leads by defining your ICP with pinpoint accuracy. Your outreach starts here. Create your Ideal Customer Profile to unlock tailored workflows and personalised content!',
  },
];

const FeatureGuideModal = () => {
  const guideModalStyle = useGuideModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isShowWelcomeCarousel = useSelector((state) => state.app.outreachBoarding.welcomeCarousel);
  const { welcomeModal, guideSteps, lmsRedirectionModal, inviteTeamRedirectionModal } =
    user?.outreachOnboarding || {};
  const [currentStep, setCurrentStep] = useState(0);
  const currentGuide = guideStepList[currentStep];
  const isSetReminderModal =
    !(lmsRedirectionModal && inviteTeamRedirectionModal) && currentStep === 0;

  useEffect(() => {
    setTimeout(() => setCurrentStep(0), 500);
  }, [isShowWelcomeCarousel]);

  useEffect(() => {
    if (welcomeModal && !guideSteps) {
      setTimeout(() => dispatch(setOutreachBoarding({ welcomeCarousel: true })), 500);
    }
  }, [user?.outreachOnboarding]);

  const handleLmsBoarding = async (isSkip, isClose) => {
    let payload = guideSteps ?? {};
    let remiderModalFlags = {};

    if (isClose) {
      payload = {
        learning: false,
        'ai-coaches': false,
        'ai-assessment': false,
        outreach: false,
        ...payload,
      };
    }

    if (isSetReminderModal) {
      remiderModalFlags = {
        lmsRedirectionModal: true,
        inviteTeamRedirectionModal: true,
      };
    }

    if (!isSkip) {
      try {
        const res = await updateUser({
          id: user.id,
          data: {
            outreachOnboarding: {
              ...(user?.outreachOnboarding || {}),
              ...remiderModalFlags,
              guideSteps: {
                ...payload,
                [currentGuide.key]: true,
              },
            },
          },
        });
        dispatch(setCurrentUser({ userData: res }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => (prev < guideStepList.length - 1 ? prev + 1 : prev));
    handleClose(null, currentStep + 1 < guideStepList.length);
  };

  const handleClose = (e, isNext = false) => {
    if (isSetReminderModal) {
      cookieUtils.set('SHOW_LMS_REDIRECTION_MODAL', true, 1);
      cookieUtils.set('SHOW_INVITE_TEAM_REDIRECTION_MODAL', true, 2);
    }

    if (!isNext) {
      dispatch(setOutreachBoarding({ welcomeCarousel: false }));
    }

    const isSkip =
      guideSteps &&
      (guideSteps[currentGuide.key] || Object.keys(guideSteps).length === guideStepList.length) &&
      !isSetReminderModal;

    handleLmsBoarding(isSkip, !isNext);
  };

  return (
    <GatedModal
      open={isShowWelcomeCarousel}
      onCloseModal={handleClose}
      className={guideModalStyle.root}
      renderHeader={
        currentGuide.heading && (
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Icon name={currentGuide.icon} />
            <Typography variant="h2" fontWeight={600} color={theme.palette.text.black1}>
              {currentGuide.heading}
            </Typography>
          </Stack>
        )
      }
      renderFooter={
        <CustomGatedFooterContent>
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" flex={1}>
            <Typography variant="subtitle2" color={theme.palette.text.grey}>
              {currentStep + 1} of {guideStepList.length}
            </Typography>

            <Stack flexDirection="row" gap={2}>
              <Button variant="text" color="primary" onClick={handleClose}>
                <Typography fontSize="inherit" color={theme.palette.text.black1}>
                  Skip
                </Typography>
              </Button>

              <Button variant="contained" color="primary" onClick={() => handleNextStep()}>
                {currentStep < guideStepList.length - 1 ? 'Next' : 'Done'}
              </Button>
            </Stack>
          </Stack>
        </CustomGatedFooterContent>
      }
      image={images.onboarding[`guideStep${currentStep + 1}`]}
    >
      <CustomGatedMainContent>
        <Stack gap={2}>
          <Typography className="title" color={theme.palette.text.black1}>
            {currentGuide.title}
          </Typography>
          <Typography color={theme.palette.text.black1}>{currentGuide.description}</Typography>
        </Stack>
      </CustomGatedMainContent>
    </GatedModal>
  );
};

export default FeatureGuideModal;
