import { Box, Card, Stack, styled, Typography } from '@mui/material';
import { Button, Spacer } from '../../../../components/shared';
import images from '../../../../config/images';
import moment from 'moment';
import { dateFormat } from '../../../../config';
import { formatCurrency } from '../../../../utils/helper';
import React from 'react';
import { LightTooltip, useStyles } from '../../../sequence/components/Sequence/AIwriterForm/styles';
import { useHistory } from 'react-router-dom';
import { basicPlans } from '../../../../config';
import MoveDownIcon from '@mui/icons-material/MoveDown';

const StyledTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
  font-family: 'Roboto', sans-serif;
`;

const StyledPlanBaseName = styled(Typography)`
  color: #000000de;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
`;

const StyledLicenceText = styled(Typography)`
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const StyledSubscriptionEnds = styled(Typography)`
  color: #00000061;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4px;
`;

const StyledSubscriptionCredits = styled(Typography)`
  color: #000000de;
  font-size: 28px;
  font-weight: 600;
  line-height: 32.676px;
`;

const StyledCreditsValue = styled(Typography)`
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

const StyledCreditUsed = styled(Typography)`
  color: #00000099;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  display: flex;
  gap: 8px;
`;

export const SubscriptionCardSub = ({
  title,
  is14DaysTrial,
  handleChangeSubscription,
  summaryData,
  tenant,
  activePlanData,
  hasFreeOneMembershipAccess,
  allSubscriptions,
  toggleRechargeModal,
  loading,
  setShowLicenses,
  subscriptions,
  itsPremiumPlan,
  initiatePayment,
  hasLmsAccess,
  hasOneMembershipAccess,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const getSubscriptionDetails = () => {
    const subscriptionDetails = { monthlyPlan: {}, yearlyPlan: {} };
    const plans = (allSubscriptions || []).flatMap((subscription) => subscription.plans);

    if (activePlanData?.yearPlanKey || activePlanData?.year?.activePlanKey) {
      const activePLanBaseDetail =
        basicPlans[
          activePlanData?.year?.data?.item_price_id || activePlanData?.month?.data?.entity_id
        ];
      subscriptionDetails.yearlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.year,
      );
      subscriptionDetails.monthlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.month,
      );
    }

    if (activePlanData?.monthPlanKey || activePlanData?.month?.activePlanKey) {
      const activePLanBaseDetail =
        basicPlans[
          activePlanData?.month?.data?.item_price_id || activePlanData?.month?.data?.entity_id
        ];
      subscriptionDetails.yearlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.year,
      );
      subscriptionDetails.monthlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.month,
      );
    }

    return subscriptionDetails;
  };

  const { monthlyPlan, yearlyPlan } = getSubscriptionDetails();

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          maxWidth: '860px',
          width: '100%',
          padding: '40px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '40px',
          borderRadius: '15px',
          background: '#FFF',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            <StyledTitle>
              Subscription - {title == 'LMS' ? 'SuperReach Ai Learning' : title} Plan
            </StyledTitle>
            {(is14DaysTrial || (hasFreeOneMembershipAccess && !itsPremiumPlan)) && (
              <Button color="secondary" onClick={() => handleChangeSubscription()}>
                <Typography
                  sx={{
                    color: '#1976D2',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.4px',
                  }}
                >
                  Upgrade
                </Typography>
              </Button>
            )}
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              alignSelf: 'stretch',
              borderRadius: '15px',
              marginTop: '24px',
            }}
          >
            <Stack
              gap={'24px'}
              direction={'row'}
              sx={{
                width: '100%',
              }}
              alignItems={'center'}
            >
              <img src={images.app.SRArrowImg} alt={''} />
              <Stack
                gap={'8px'}
                sx={{
                  width: '100%',
                }}
              >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <StyledPlanBaseName>
                    {!itsPremiumPlan && !hasFreeOneMembershipAccess && is14DaysTrial
                      ? '14 days'
                      : 'Basic monthly'}{' '}
                    - ${monthlyPlan?.price || 0} pm
                  </StyledPlanBaseName>
                  <Button
                    sx={{
                      borderRadius: '4px',
                      background: '#1976D2',
                    }}
                    loading={loading ? loading === monthlyPlan.planId : false}
                    disabled={loading}
                    onClick={() => {
                      // setShowLicenses({
                      //   planName: monthlyPlan?.planName,
                      //   monthlyPlan,
                      //   subscriptions: subscriptions?.subscription?.[monthlyPlan?.planKey],
                      //   open: true,
                      // });
                      initiatePayment(
                        monthlyPlan?.planId,
                        subscriptions?.subscription?.[monthlyPlan?.planKey],
                        false,
                      );
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#FFF',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.4px',
                      }}
                    >
                      Add licenses
                    </Typography>
                  </Button>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <StyledLicenceText>
                    {activePlanData?.month?.data?.quantity || 0} licenses assigned
                  </StyledLicenceText>
                  <StyledSubscriptionEnds>
                    {activePlanData?.month?.data?.next_billing_at &&
                      `Subscription ends:
                      ${moment
                        .unix(activePlanData?.month?.data?.next_billing_at)
                        .format(dateFormat)}`}
                  </StyledSubscriptionEnds>
                </Stack>
              </Stack>
            </Stack>

            {itsPremiumPlan && (
              <Stack
                gap={'24px'}
                direction={'row'}
                sx={{
                  width: '100%',
                }}
                alignItems={'center'}
              >
                <img src={images.app.SRArrowImg} alt={''} />
                <Stack
                  gap={'8px'}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledPlanBaseName>
                      Basic annual - ${yearlyPlan?.price || 0} py
                    </StyledPlanBaseName>
                    <Button
                      sx={{
                        borderRadius: '4px',
                        background: '#1976D2',
                      }}
                      loading={loading ? loading === yearlyPlan?.planId : false}
                      disabled={loading}
                      onClick={() => {
                        // setShowLicenses({
                        //   planName: yearlyPlan?.planName,
                        //   monthlyPlan,
                        //   subscriptions: subscriptions?.subscription?.[yearlyPlan?.planKey],
                        //   open: true,
                        // });
                        initiatePayment(
                          yearlyPlan.planId,
                          subscriptions?.subscription?.[yearlyPlan?.planKey],
                          false,
                        );
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#FFF',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '24px',
                          letterSpacing: '0.4px',
                        }}
                      >
                        Add licenses
                      </Typography>
                    </Button>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledLicenceText>
                      {activePlanData?.year?.data?.quantity &&
                        `${activePlanData?.year?.data?.quantity} licenses assigned`}
                    </StyledLicenceText>
                    <StyledSubscriptionEnds>
                      {activePlanData?.year?.data?.next_billing_at &&
                        `Subscription ends:
                        ${moment
                          .unix(activePlanData?.year?.data?.next_billing_at)
                          .format(dateFormat)}`}
                    </StyledSubscriptionEnds>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
      </Card>

      <Card
        sx={{
          marginTop: '37px',
          display: 'flex',
          maxWidth: '860px',
          width: '100%',
          padding: '40px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '40px',
          borderRadius: '15px',
          background: '#FFF',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            <Box>
              <StyledTitle>Pay As You Go services</StyledTitle>
              <Spacer x={1} y={1} />
              <Typography variant="body1" color="textSecondary">
                Data enrichment, Email validation, AI Co-workers and AI Coaches
              </Typography>
            </Box>
            <Button
              color="secondary"
              onClick={() => {
                history.push('/admin/spendinglimits');
              }}
              startIcon={<MoveDownIcon sx={{ color: '#1976D2' }} />}
            >
              Manage spending limits
            </Button>
          </Stack>
          <Box>
            <Stack
              gap={'24px'}
              direction={'row'}
              sx={{
                width: '100%',
              }}
              alignItems={'center'}
            >
              <img
                src={images.app.payGIcon}
                style={{ marginTop: '18px', width: '60px', height: '60px' }}
              />
              <Stack
                gap={'8px'}
                sx={{
                  width: '100%',
                  marginTop: '24px',
                }}
              >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <StyledPlanBaseName sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    Balance Available - ${formatCurrency(tenant?.credits)}
                  </StyledPlanBaseName>
                  <Button
                    sx={{
                      borderRadius: '4px',
                      background: '#1976D2',
                    }}
                    onClick={() => {
                      toggleRechargeModal('data');
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#FFF',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.4px',
                      }}
                    >
                      Add credits
                    </Typography>
                  </Button>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <StyledCreditUsed>
                    Balance used last in the last month :{' '}
                    <StyledCreditsValue component={'span'}>
                      ${formatCurrency(summaryData?.creditsUsed)}
                    </StyledCreditsValue>
                  </StyledCreditUsed>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default SubscriptionCardSub;
