import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Stack, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'src/components/shared';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import SpendingLimitSection from './SpendingLimitSection';
import LearnMoreModal from './LearnMoreModal';
import { fetchTenantSpendingLimit, updateTenantSpendingLimit } from '../../api/adminApi';
import { useSelector } from 'react-redux';

const sections = [
  {
    title: 'Data and Email',
    description: 'Set your monthly spending limit for finding and validating contact information',
    balance: '$150',
    color: '#00B295',
    key: 'data',
    tooltip:
      'Data and Email services support profile enrichment by sourcing emails and phone numbers, while Email Validation ensures their accuracy. Each successful request incurs a cost, deducted from your Data balance.',
  },
  {
    title: 'AI Coach',
    key: 'aiCoach',
    description: 'Set your monthly spending limit for AI Coaching and Assessments',
    balance: '$200',
    color: '#F240A4',
    tooltip:
      'AI Coaching refers to AI experiences on the learning platform including AI Coaching and AI Assessments. Each AI session incurs a cost, which is deducted from your AI Coaching balance.',
  },
  {
    title: 'AI Co-Workers',
    key: 'aiCoWorkers',
    description:
      'Set your monthly spending limit for AI tasks such as content generation and task execution',
    balance: '$300',
    color: 'linear-gradient(244.32deg, #FFD480 23.65%, #F240A4 56.41%, #1976D2 95.33%)',
    tooltip:
      'AI Co-Workers handle tasks like creating content, generating sequences, and running workflows. Each task incurs a cost, which is deducted from your AI Co-Worker balance.',
  },
];

const defaultOveride = {
  user: '',
  userLimit: 0,
  action: 'add',
};

const SpendingLimits = () => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user);
  const tenantId = currentUser.tenants?.[0].id;
  const [spendingLimits, setSpendingLimits] = useState({});
  const [customLimitUsers, setcustomLimitUsers] = useState({});
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSpendingLimits = async () => {
    try {
      const response = await fetchTenantSpendingLimit(tenantId);
      setSpendingLimits({ ...response.spendingLimits });
      setcustomLimitUsers({ ...response.customLimitUsers });
    } catch (error) {
      console.error('Error fetching spending limits:', error);
    }
  };

  useEffect(() => {
    fetchSpendingLimits();
  }, [tenantId]);

  const initialValues = useMemo(() => {
    const spendingLimitsArray = Object.values(spendingLimits);
    return sections.reduce((acc, section) => {
      const matchingLimit = spendingLimitsArray.find((limit) => limit.type === section.key) || {};
      const customLimitUsersInSection = Object.values(customLimitUsers)
        .filter((user) => user.spendingLimits.some((limit) => limit.type === section.key))
        .map((user) => ({
          user: { ...user, name: user?.fname + ' ' + user?.lname, id: user.id },
          userLimit:
            user.spendingLimits.find((limit) => limit.type === section.key)?.customLimit || 0,
          action: 'add',
        }));
      acc[section.key] = {
        defaultAccountLimit: matchingLimit.tenantLimit || 0,
        defaultUserLimit: matchingLimit.userLimit || 0,
        overrides:
          customLimitUsersInSection.length > 0
            ? [...customLimitUsersInSection]
            : [{ ...defaultOveride }],
      };
      return acc;
    }, {});
  }, [spendingLimits, sections, customLimitUsers, fetchSpendingLimits]);

  const handleSave = async (values) => {
    try {
      setIsLoading(true); 
      const sectionData = sections.map((section) => {
        const sectionValues = values[section.key] || {};
        const tenantLimit = sectionValues.defaultAccountLimit || 0;
        const userLimit = sectionValues.defaultUserLimit || 0;
        const customLimits =
          sectionValues.overrides
            ?.filter((override) => override.user !== '')
            ?.map((override) => ({
              id: override.user._id,
              customLimit: parseInt(override.userLimit),
              action: override.action || 'add',
            })) || [];

        return {
          type: section.key,
          tenantLimit: parseInt(tenantLimit),
          userLimit: parseInt(userLimit),
          customLimits,
        };
      });

      await updateTenantSpendingLimit(tenantId, sectionData);
      await fetchSpendingLimits();
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
    }
  };

  const updatedSections = sections.map((section) => {
    const customLimitUsersArray = Object.values(customLimitUsers);
    const customLimitUsersInSection = customLimitUsersArray
      .filter((user) => user.spendingLimits.some((limit) => limit.type === section.key))
      .map((user) => ({
        id: user._id,
        fullName: `${user.fname} ${user.lname}`,
        customLimit: user.spendingLimits.find((limit) => limit.type === section.key)?.customLimit,
      }));

    return {
      ...section,
      users: [...customLimitUsersInSection],
      userCount: customLimitUsersInSection.length,
    };
  });

  const handleAddUser = (sectionKey, newUser, newUserLimit, action) => {
    const existingUsers = Object.keys(customLimitUsers).length;
    const userAlreadyExists = Object.values(customLimitUsers).some((user) => {
      return user.spendingLimits.some(
        (limit) => limit.type === sectionKey && user.id === newUser.id,
      );
    });

    if (userAlreadyExists || action === 'remove') {
      return;
    }

    setcustomLimitUsers((prev) => ({
      ...prev,
      [existingUsers]: {
        ...newUser,
        spendingLimits: [
          ...(prev[newUser.id]?.spendingLimits || []),
          { type: sectionKey, customLimit: newUserLimit },
        ],
      },
    }));
  };

  const handleRemoveUser = (sectionKey, userId) => {
    console.log(sectionKey, userId);
    setcustomLimitUsers((prev) => {
      const updatedUsers = Object.values(prev).map((user) => {
        if (user._id === userId) {
          const updatedSpendingLimits = user.spendingLimits.filter(
            (limit) => limit.type !== sectionKey,
          );
          return { ...user, spendingLimits: updatedSpendingLimits };
        }
        return user;
      });
      return updatedUsers.reduce((acc, user, index) => ({ ...acc, [index]: user }), {});
    });
  };

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Form enableReinitialize={true} initialValues={initialValues}>
          {({ values }) => (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mb: 2 }}
              >
                <Box>
                  <Stack direction="row" gap={1} alignItems="center" mb={1}>
                    <MoveDownIcon sx={{ marginRight: '6px', fontSize: '25px', color: '#666666' }} />
                    <Typography variant="h2">Monthly Spending Limits</Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setIsLearnMoreOpen(true)}
                    >
                      Learn more
                    </Typography>
                  </Stack>
                  <Box ml={5}>
                    <Typography variant="body2" color="textSecondary">
                      Control your monthly spend for pay-as-you-go services
                    </Typography>
                  </Box>
                </Box>
                <Stack direction="row" gap={1}>
                  <Button
                    variant="text"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => handleSave(values)}>
                    Save
                  </Button>
                </Stack>
              </Box>

              {updatedSections.map((section, index) => (
                <SpendingLimitSection
                  key={index}
                  section={section}
                  values={values}
                  defaultOveride={defaultOveride}
                  onAddUser={handleAddUser}
                  onRemoveUser={handleRemoveUser}
                />
              ))}
            </>
          )}
        </Form>
      )}
      <LearnMoreModal open={isLearnMoreOpen} onClose={() => setIsLearnMoreOpen(false)} />
    </Box>
  );
};

export default SpendingLimits;
