import React, { useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useGuideModalStyles } from './styles';
import { CustomGatedFooterContent, CustomGatedMainContent } from '../styles';
import GatedModal from '../GatedModal';
import { Button } from 'src/components/shared';
import cookieUtils from 'src/utils/cookieUtils';
import images from 'src/config/images';
import Icon from 'src/components/lms/Icon';

const LMSPlatformModal = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const userJourney = useSelector((state) => state.app.outreachBoarding?.journey);
  const guideModalStyle = useGuideModalStyles();
  const isShowLmsRedirectionModal = cookieUtils.get('SHOW_LMS_REDIRECTION_MODAL');
  const [showLmsModal, setShowLmsModal] = useState(true);

  const hasLmsAccess = useMemo(() => {
    return user?.lmsmonthly === true || user?.lmsannual === true;
  }, [user]);

  const isShowLmsModal = useMemo(
    () =>
      showLmsModal &&
      hasLmsAccess &&
      !isShowLmsRedirectionModal &&
      !userJourney?.lmsOnboardingCompleted &&
      user?.outreachOnboarding?.lmsRedirectionModal,
    [user, showLmsModal],
  );

  const redirectOnLmsScreen = () => {
    cookieUtils.unset('SHOW_LMS_REDIRECTION_MODAL');
    window.location.replace(`${process.env.REACT_APP_LMS_URL}`);
    onClose();
  };

  const onClose = () => {
    cookieUtils.set('SHOW_LMS_REDIRECTION_MODAL', true, 1);
    setShowLmsModal(false);
  };

  return (
    <GatedModal
      open={isShowLmsModal}
      onCloseModal={onClose}
      className={guideModalStyle.root}
      renderHeader={
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Icon name="itemplay" />
          <Typography variant="h2" fontWeight={600} color={theme.palette.text.black1}>
            Learning
          </Typography>
        </Stack>
      }
      renderFooter={
        <CustomGatedFooterContent>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap={2}>
            <Button variant="text" color="primary" onClick={onClose}>
              <Typography fontSize="inherit" color={theme.palette.text.grey}>
                Later
              </Typography>
            </Button>
            <Button variant="contained" color="primary" onClick={redirectOnLmsScreen}>
              Go to Learning
            </Button>
          </Stack>
        </CustomGatedFooterContent>
      }
      image={images.onboarding.guideStep6}
    >
      <CustomGatedMainContent>
        <Stack gap={2}>
          <Typography className="title" color={theme.palette.text.black1}>
            Don’t miss out on 100s of FREE training sessions!
          </Typography>
          <Typography color={theme.palette.text.black1}>
            Get unlimited access to hundreds of sales and recruitment training sessions and
            personalised AI coaches to fast-track your learning goals!
          </Typography>
        </Stack>
      </CustomGatedMainContent>
    </GatedModal>
  );
};

export default LMSPlatformModal;
