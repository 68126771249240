import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, Typography, Alert, FormHelperText, MenuItem, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';

import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { saveTemplate, fetchTemplateDetails, updateTemplate } from 'src/modules/admin/api/adminApi';
import { fetchTeamLookup, fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { bhCandidateSearch } from 'src/modules/contacts/api/contactApis';
import { getIntegrations } from 'src/modules/app/actions/appSelector';
import SequenceFormTabBar from '../SequenceFormTabBar';
import AIContentView from '../SequenceFormTabBar/AIContentView';
import { SavingTemplateButton } from '../StyleComponent';
import AdvanceSetting from '../SequenceFormTabBar/AdvanceSetting';
import useFeatureAccess from '../../../../../components/hooks/useFeatureAccess';

const EmailForm = ({
  onSubmit,
  action,
  loading,
  handleClose,
  event,
  openAIWriter,
  setOpenAIWriter,
  saveAsNew,
  setSaveAsNew,
  selectedAiResponse,
  setSelectedAiResponse,
  ...props
}) => {
  const formikRef = React.useRef();
  const tenant = useSelector((state) => state.app.tenant);
  const loginUser = useSelector((state) => state.auth.user);

  const [template, setTemplate] = useState({});
  const [templateAutomationType, setTemplateAutomationType] = useState(event.mode || 'automation');
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);

  const integrations = useSelector((state) => getIntegrations(state));
  const templateCategoryList = useSelector((state) => state.app.globals.templateCategoryList);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const sequenceAccessTypeList = useSelector((state) => state.app.globals?.sequenceAccessTypeList);

  const options = useMemo(() => {
    if (templateCategoryList && templateCategoryList.length) {
      return templateCategoryList;
    }
    return [];
  }, [integrations, templateCategoryList]);

  const existingTemplates = useMemo(() => {
    if (!event?.emailTemplates || event.emailTemplates.length === 0) {
      return [];
    }
    return event.emailTemplates.map((item) => item.id);
  }, [event]);

  useEffect(() => {
    if (template?.attachments && template.attachments.length) {
      // fetchAttachments();
    }
  }, [template?.attachments]);

  useEffect(() => {
    if (template.id) {
      setFetchingTemplates(true);
      fetchTemplateDetails(template.id)
        .then((res) => {
          setFetchingTemplates(false);
          setTemplate(res.template);
          setFiles([...files, ...res.template.attachments]);
        })
        .catch((err) => {
          setFetchingTemplates(false);
          setTemplate([]);
        });
    }
  }, [template.id]);

  const fetchTemplatesDetails = async (val, type) => {
    const newTemplates = await fetchTemplatesLookup(val, type);
    if (newTemplates && newTemplates.length > 0) {
      const sequenceResults = newTemplates
        .sort(
          (a, b) =>
            (a.permissionType === '') - (b.permissionType === '') ||
            a.permissionType - b.permissionType,
        )
        .map((option) => {
          var permissionType =
            option.permissionType === 'team'
              ? 'Team'
              : option.permissionType === 'private'
              ? 'Private'
              : 'Organisation';
          return {
            accessType: permissionType,
            ...option,
          };
        });
      return await sequenceResults;
    }
  };

  useEffect(() => {
    if (selectedAiResponse?.body) {
      if (formikRef.current) {
        const formattedContent = selectedAiResponse?.body?.replace(/\n/g, '<br/>');
        formikRef.current?.setFieldValue('subject', selectedAiResponse?.subject);
        formikRef.current?.setFieldValue('description', formattedContent);
      }
    }
  }, [selectedAiResponse]);

  useEffect(() => {
    setTemplate(props.template);
    setDescription(props.template?.content);
  }, [event, props.template]);

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    const bhCandidate = data.bhCandidate;
    delete data.bhCandidate;
    const attachments =
      data.attachments && data.attachments.length
        ? JSON.parse(JSON.stringify(data.attachments))
        : [];

    let saveAsNew = data.saveAsNew;
    let sourceTemplate = '';
    let templateType = 'email';

    if (template?.id && template?.id !== '') {
      if (template?.id == data.emailTemplates?.id) {
        await updateTemplate(template?.id, {
          // type: 'email',
          subject: data.subject,
          content: data.description,
          category: data.category,
          isPublished: true,
          attachments: attachments.length ? attachments.map((file) => file.id) : [],
        });
      }
    }

    if (data.emailTemplates && data.emailTemplates?.id && data.emailTemplates?.id !== '') {
      if (template?.id && template?.id !== '' && template?.id == data.emailTemplates?.id) {
        if (!existingTemplates.includes(data.emailTemplates.id)) {
          data.emailTemplates = [...existingTemplates, data.emailTemplates.id];
        } else {
          data.emailTemplates = [...existingTemplates];
        }
        saveAsNew = false;
      } else {
        if (data.saveAsNew === true) {
          templateType = 'email';
        } else {
          templateType = 'clonedEmail';
          sourceTemplate = data.emailTemplates.id;
        }
        saveAsNew = true;
      }
    } else {
      templateType = data.saveAsNew === true ? 'email' : 'clonedEmail';
      saveAsNew = true;
      data.name = data.name && data.name !== '' ? data.name : data.subject;
    }

    // data.sender = data?.sender && data?.sender?.id ? data.sender.id : null;
    delete data?.sender;
    data.mode = templateAutomationType;
    if (saveAsNew === true) {
      let newTemplate = await saveTemplate({
        type: templateType,
        name: data.name,
        subject: data.subject,
        content: data.description,
        category: data.category,
        isPublished: true,
        ...(data.saveAsNew === false && { personalisedEmail: true }),
        files: attachments.length ? attachments.map((file) => file.id) : [],
        attachments: attachments.length ? attachments.map((file) => file.id) : [],
        sharedWithTeams: data.sharedWithTeams.map((team) => team.id),
        sharedWithUsers: data.sharedWithUsers.map((user) => user.id),
        permissionType: data.permissionType,
        ...(templateType === 'clonedEmail' && { sourceTemplate: data.emailTemplates.id }),
        ...(data.category === 'cv-spec' &&
          bhCandidate?.bhCandidateId !== '' && {
            bhCandidateId: bhCandidate.bhCandidateId,
            bhCandidateUrl: bhCandidate.url,
            bhCandidateName: bhCandidate.name,
          }),
      });

      newTemplate = newTemplate.template;
      if (action === 'addTemplate') {
        data.emailTemplates = [newTemplate.id];
      } else {
        if (!existingTemplates.includes(data.emailTemplates.id)) {
          data.emailTemplates = [...existingTemplates, newTemplate.id];
        }
        const oldIndex = data.emailTemplates.indexOf(template?.id);
        if (oldIndex > -1) {
          data.emailTemplates.splice(oldIndex, 1);
        }
        data.templateProps = {
          ...event?.templateProps,
          [newTemplate.id]: {
            status: true,
          },
        };
      }
      delete data.category;
      delete data.subject;
      onSubmit(data);
    } else {
      delete data.category;
      delete data.subject;
      data.attachments = attachments.length ? attachments.map((file) => file.id) : [];
      data.files = data.attachments;
      onSubmit(data);
    }
  };
  const generateEmailTemplateWithAI = useFeatureAccess('generateEmailTemplateWithAI');

  return (
    <>
      {!saveAsNew && (
        <SequenceFormTabBar
          showAiWriter={generateEmailTemplateWithAI}
          templateAutomationType={templateAutomationType}
          setTemplateAutomationType={setTemplateAutomationType}
        />
      )}
      <Form
        innerRef={formikRef}
        initialValues={{
          eventType: 'email',
          name: template?.name || '',
          subject: template?.subject || '',
          emailTemplates: template,
          description: description,
          category: template?.category || '',
          saveAsNew: saveAsNew,
          attachments: files,
          permissionType: template?.permissionType || '',
          bhCandidate:
            template?.bhCandidateId !== ''
              ? {
                  bhCandidateId: template?.bhCandidateId,
                  name: template?.bhCandidateName,
                  url: template?.bhCandidateUrl,
                }
              : {},
          sharedWithUsers: [],
          sharedWithTeams: [],
          // sender: defSender,
          isAutoClose: event?.isAutoClose || false,
          autoCloseDuration: event?.autoCloseDuration ? parseInt(event?.autoCloseDuration) : 0,
          sendFromAnotherUser: event?.assignedto?.length > 0 || false,
          startNewConversation: event?.startNewConversation || false,
          assignedto: event?.assignedto || [],
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required('Please enter description!'),
          subject: Yup.string().required('Please enter subject!'),
          name: Yup.string().test({
            message: () => 'Please enter template name!',
            test: (values, { options }) => {
              if (!values && options.parent.saveAsNew === false) return true;
              return values && typeof values !== 'undefined' && values !== '';
            },
          }),
          autoCloseDuration: Yup.number()
            .integer()
            .test({
              message: () => 'Please choose Auto-Close duration!',
              test: (values, { options }) => {
                if (!values && options.parent.isAutoClose === false) return true;
                return values && values > 0;
              },
            }),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
              style={{ height: '100%' }}
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                height="100%"
                width="100%"
              >
                <Box width="100%">
                  {values?.saveAsNew ? (
                    <>
                      <Form.Field.Input
                        size={'small'}
                        fullWidth
                        variant="outlined"
                        name="name"
                        label="Template name"
                        sx={{ marginBottom: 0 }}
                        isSmall
                        onChange={(e) => setTemplate((prevState) => ({ ...prevState, name: e }))}
                      />
                      <Form.Field.Select
                        size={'small'}
                        options={options}
                        fullWidth
                        variant="outlined"
                        name="category"
                        label="Template category"
                        sx={{ marginBottom: 0 }}
                        isSmall
                        onChange={(e) =>
                          setTemplate((prevState) => ({ ...prevState, category: e }))
                        }
                      />
                    </>
                  ) : (
                    <Form.Field.AutoComplete
                      options={[template]}
                      fullWidth
                      clearOnEscape={true}
                      name="emailTemplates"
                      isSmall
                      variant={'outlined'}
                      placeholder="Select template"
                      groupBy={(option) => option?.accessType || ''}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(val) => {
                        if (val) {
                          setTemplate(val);
                          setDescription(val.content);
                        }
                      }}
                      remoteMethod={(val) => {
                        return fetchTemplatesDetails(val, 'email');
                      }}
                      loading={fetchingTemplates}
                      optLabel="name"
                      optValue="id"
                      multiple={false}
                      disabled={values.saveAsNew}
                    />
                  )}

                  <AIContentView
                    formType="email"
                    placeHolder={'Your email…'}
                    showAiWriter={generateEmailTemplateWithAI}
                    withUpload
                    showTemplateList={values.saveAsNew === true}
                    options={replacementVariables}
                    setOpenAIWriter={setOpenAIWriter}
                    formProps={{ ...formProps, values }}
                  />

                  {values.saveAsNew === true ? (
                    <>
                      <Form.Field.Select
                        options={sequenceAccessTypeList || []}
                        fullWidth
                        variant="outlined"
                        size={'small'}
                        name="permissionType"
                        label="Permission Type"
                        showNone={false}
                        optLabel="label"
                        optValue="value"
                        isSmall
                        sx={{ marginBottom: 0 }}
                        // tip="Select who has access to this template."
                        onChange={(val) => {
                          switch (val) {
                            case 'private':
                              formProps.setFieldValue('sharedWithUsers', [
                                { ...loginUser, name: loginUser.fname + ' ' + loginUser.lname },
                              ]);
                              break;
                            case 'team':
                              formProps.setFieldValue('sharedWithTeams', []);
                              break;
                            case 'organisation':
                            default:
                              formProps.setFieldValue('sharedWithUsers', []);
                              formProps.setFieldValue('sharedWithTeams', []);
                              break;
                          }
                        }}
                      />
                      {values.category === 'cv-spec' && integrations.isBullhornActive && (
                        <Form.Field.AutoComplete
                          multiple={false}
                          options={[]}
                          fullWidth
                          formSx={{ marginBottom: 0 }}
                          InputLabelProps={{
                            sx: {
                              fontSize: '14px',
                            },
                          }}
                          size={'small'}
                          variant="outlined"
                          name="bhCandidate"
                          label="Select Candidate"
                          remoteMethod={async (val) => {
                            const res = await bhCandidateSearch(val);
                            return res.candidates;
                          }}
                          renderOption={(option, props) => {
                            return (
                              <MenuItem {...props}>
                                <Box>
                                  <Typography color="textPrimary">{option.name}</Typography>
                                  <Stack direction="row" gap={0.5}>
                                    <Typography color="textSecondary" variant="body2">
                                      {option.jobTitle}
                                    </Typography>
                                    <Typography color="secondary" variant="body2">
                                      at
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2">
                                      {option.employer}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </MenuItem>
                            );
                          }}
                          optLabel="name"
                          optValue="bhCandidateId"
                        />
                      )}
                      {values.permissionType === 'private' ? (
                        <Form.Field.AutoComplete
                          multiple={true}
                          options={values?.sharedWithUsers || users}
                          fullWidth
                          formSx={{ marginBottom: 0 }}
                          InputLabelProps={{
                            sx: {
                              fontSize: '14px',
                            },
                          }}
                          size={'small'}
                          variant="outlined"
                          name="sharedWithUsers"
                          label="Select users"
                          remoteMethod={(val) => {
                            return fetchUsersLookup(val);
                          }}
                          checkboxes={true}
                          optLabel="name"
                          optValue="id"
                        />
                      ) : null}

                      {values.permissionType === 'team' ? (
                        <Form.Field.AutoComplete
                          multiple={true}
                          options={values?.sharedWithTeams || teams}
                          fullWidth
                          formSx={{ marginBottom: 0 }}
                          InputLabelProps={{
                            sx: {
                              fontSize: '14px',
                            },
                          }}
                          size={'small'}
                          variant="outlined"
                          name="sharedWithTeams"
                          checkboxes={true}
                          label="Select Teams"
                          remoteMethod={(val) => {
                            return fetchTeamLookup(val);
                          }}
                          optLabel="name"
                          optValue="id"
                        />
                      ) : null}
                    </>
                  ) : null}

                  {/*advance setting*/}
                  <AdvanceSetting
                    values={values}
                    name={'isAutoClose'}
                    formProps={formProps}
                    selectName={'autoCloseDuration'}
                    anotherUser={'sendFromAnotherUser'}
                    anotherUsersList={'assignedto'}
                    startNewConversation={'startNewConversation'}
                  />
                </Box>
                <Box mt={2} display="flex" justifyContent="end" width="100%">
                  {values?.saveAsNew ? (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        loading={loading || fetchingTemplates}
                        disabled={loading}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <SavingTemplateButton
                      setSaveAsNew={setSaveAsNew}
                      setFieldValue={formProps.setFieldValue}
                      template={template}
                      onMenuClick={(index) => {
                        if (index === 1) {
                          setTemplate(values);
                          setDescription(values?.description);
                        }
                      }}
                    />
                  )}
                </Box>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
};

export default EmailForm;
