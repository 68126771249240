import React, { useState } from 'react';
import { Box, Typography, Tooltip, Card, Grid, AvatarGroup, Stack } from '@mui/material';
import { Button, Avatar, Form, Spacer } from 'src/components/shared';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddNewUserDialog from './AddNewUserDialog';

const SpendingLimitSection = ({ section, values, defaultOveride, onAddUser, onRemoveUser }) => {
  const [open, setOpen] = useState(false);

  const { title, description, balance, users, userCount, color, tooltip } = section;

  const handleAddUser = () => {
    setOpen(true);
  };

  const handleClose = (allOverrides) => {
    if (allOverrides) {
      values[section.key].overrides = allOverrides;
      allOverrides.forEach((override) => {
        if (override.action === 'add' && override.user) {
          onAddUser(section.key, override.user, override.userLimit, override.action);
        } else if (override.action === 'remove' && override.user) {
          onRemoveUser(section.key, override.user._id);
        }
      });
    }
    setOpen(false);
  };

  return (
    <Card
      sx={{
        p: 3,
        mb: 2,
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        boxShadow: 'none',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: '10px',
          background: color.includes('linear-gradient') ? color : undefined,
          backgroundColor: !color.includes('linear-gradient') ? color : undefined,
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
        }}
      />
      <Grid container spacing={2} alignItems="center">
        {/* First Column: Title, Description, and Balance */}

        <Grid item xs={12} md={5}>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
              <Typography variant="body1" color="textPrimary">
                <b>{title}</b>
              </Typography>
              <Tooltip
                title={tooltip}
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: '200px',
                      fontWeight: '300',
                      bgcolor: '#1A2633',
                      borderRadius: '8px',
                      padding: '8px 10px',
                      '& .MuiTooltip-arrow': {
                        color: '#1A2633',
                      },
                    },
                  },
                }}
              >
                <HelpOutlineIcon
                  sx={{
                    fontSize: '24px',
                    color: '#999999',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Stack>
          </Box>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
          <Spacer x={1} y={1} />
          <Typography variant="body2" color="textPrimary">
            Balance used in the last month: {balance}
          </Typography>
        </Grid>

        {/* Second Column: Input Fields */}
        <Grid item xs={12} md={4}>
          <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
            <Box>
              <Typography variant="body1" color="textPrimary">
                Default Account Limit
              </Typography>
              <Form.Field.Input
                name={`${section.key}.defaultAccountLimit`}
                placeholder="$500"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary">
                Default User Limit
              </Typography>
              <Form.Field.Input
                name={`${section.key}.defaultUserLimit`}
                placeholder="$500"
                variant="outlined"
                fullWidth
              />
            </Box>
          </Stack>
        </Grid>

        {/* Third Column: Avatars and Button */}
        <Grid item xs={12} md={3} sx={{ mt: 2 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ mb: 1 }}>
            <AvatarGroup spacing="medium" max={6}>
              {users.map((user, index) => (
                <Avatar key={index} name={user.fullName} />
              ))}
            </AvatarGroup>
            <Typography color="textSecondary" variant="body2">
              {userCount} Users
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" onClick={handleAddUser}>
              <MoveDownIcon sx={{ marginRight: '6px' }} color="secondary" />
              <Typography variant="body2" color="secondary">
                Add Custom User Limits
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <AddNewUserDialog
        open={open}
        onClose={handleClose}
        sectionKey={section.key}
        values={values}
        defaultOveride={defaultOveride}
      />
    </Card>
  );
};

export default SpendingLimitSection;
