import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useWelcomeModalStyles } from './style';
import { Button } from 'src/components/shared';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomGatedFooterContent,
  CustomGatedHeaderContent,
  CustomGatedMainContent,
} from '../styles';
import Icon from 'src/components/lms/Icon';
import GatedModal from '../GatedModal';
import { updateUser } from 'src/modules/auth/api/authApis';
import { setCurrentUser } from 'src/modules/auth/actions/authActions';
import { setOutreachBoarding } from 'src/modules/app/actions/appActions';

const WelcomeModal = () => {
  const freeTrialStyle = useWelcomeModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userJourney = useSelector((state) => state.app.outreachBoarding?.journey);
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(true);
  const isInvitedUser = !user.isSignedUpUser;
  const isLMSJourneyCompleted = userJourney?.lmsOnboardingCompleted;

  const isShowWelcomeModal =
    user.id &&
    !user.outreachOnboarding?.welcomeModal &&
    userJourney.onboardingCompleted &&
    showWelcomeModal;

  const handleLmsBoarding = async () => {
    setShowWelcomeModal(false);
    try {
      const response = await updateUser({
        id: user.id,
        data: {
          outreachOnboarding: {
            welcomeModal: true,
          },
        },
      });
      dispatch(setCurrentUser({ userData: response }));
    } catch (e) {
      console.error(e);
    }
    dispatch(setOutreachBoarding({ welcomeCarousel: true }));
  };

  return (
    <GatedModal
      open={isShowWelcomeModal}
      onCloseModal={handleLmsBoarding}
      className={freeTrialStyle.root}
      renderHeader={
        <CustomGatedHeaderContent>
          <Typography className="title" mb={3} color={theme.palette.text.black3}>
            {isLMSJourneyCompleted ? (
              'Welcome to Outreach!'
            ) : isInvitedUser ? (
              'Welcome to SuperReach!'
            ) : (
              <>
                Welcome to your 7 day{' '}
                <Typography
                  component="strong"
                  className="title"
                  color="inherit"
                  textTransform="uppercase"
                  fontWeight={500}
                >
                  free
                </Typography>{' '}
                trial!
              </>
            )}
          </Typography>
        </CustomGatedHeaderContent>
      }
      renderFooter={
        <CustomGatedFooterContent>
          <Button variant="contained" color="primary" onClick={handleLmsBoarding}>
            Let’s get started
          </Button>
          {!isInvitedUser && !isLMSJourneyCompleted && (
            <Typography variant="caption" color={theme.palette.text.grey} mt={5}>
              You can purchase additional credits anytime to keep the momentum going.
            </Typography>
          )}
        </CustomGatedFooterContent>
      }
    >
      <CustomGatedMainContent>
        <Typography
          textAlign={isLMSJourneyCompleted || isInvitedUser ? 'center' : 'left'}
          color={theme.palette.text.black1}
        >
          {isLMSJourneyCompleted
            ? 'Ready to scale your outreach and close more deals? We’ve built tools to help you connect with the right prospects, faster and smarter.'
            : isInvitedUser
            ? 'You’ve been invited to experience the all-in-one platform to simplify your work, boost performance, and drive results.'
            : 'Experience the full power of our AI platform with exclusive trial credits to get started. '}
        </Typography>
        {!isLMSJourneyCompleted && (
          <Stack className="description">
            <Stack className="item">
              <Icon name="itemplay" />
              <Typography color={theme.palette.text.black1}>
                <Typography component="strong" color="inherit">
                  {isInvitedUser ? 'Training:' : '100s'}
                </Typography>
                {isInvitedUser
                  ? ' Master sales and recruitment with expert resources.'
                  : ' of sales and recruitment training sessions and resources'}
              </Typography>
            </Stack>
            <Stack className="item">
              <Icon name="trillion" />
              <Typography color={theme.palette.text.black1}>
                <Typography component="strong" color="inherit">
                  AI coaches{isInvitedUser ? ':' : ''}
                </Typography>
                {isInvitedUser
                  ? ' Improve with personalised roleplays and feedback.'
                  : ' to practice, assess and feedback on performance'}
              </Typography>
            </Stack>
            <Stack className="item">
              <Icon name="code" />
              <Typography color={theme.palette.text.black1}>
                <Typography component="strong" color="inherit">
                  Outreach{isInvitedUser ? ':' : ''}
                </Typography>
                {isInvitedUser
                  ? ' Build stronger pipelines with top data providers.'
                  : ' platform with access to 10 leading data providers '}
              </Typography>
            </Stack>
            <Stack className="item">
              <Icon name="rebase" />
              <Typography color={theme.palette.text.black1}>
                <Typography component="strong" color="inherit">
                  AI co-workers{isInvitedUser ? ':' : ''}
                </Typography>
                {isInvitedUser
                  ? ' Automate tasks and boost productivity.'
                  : ' to execute tasks and workflows for you'}
              </Typography>
            </Stack>
            <Typography textAlign="center" color={theme.palette.text.black1}>
              {isInvitedUser
                ? 'Start working smarter today. Click below to begin!'
                : 'Click below to begin – it only takes a minute!'}
            </Typography>
          </Stack>
        )}
      </CustomGatedMainContent>
    </GatedModal>
  );
};

export default WelcomeModal;
