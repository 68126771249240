import React, { useEffect, useMemo } from 'react';
import Router from 'src/router';
import { connect } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Switch } from 'react-router-dom';

import Toast from 'src/components/shared/Toast';
import theme from 'src/config/theme';
import darkTheme from 'src/config/darkTheme';

import AppLayout from 'src/layouts/AppLayout';
import AuthLayout from 'src/layouts/AuthLayout';
import AppLoader from 'src/layouts/AppLoader';
import LearningLayout from 'src/layouts/learning';

import { authPages, isPathMatched, standAlonePages } from 'src/config';
import { restoreSession } from 'src/modules/auth/actions/authActions';
import ReactGA from 'react-ga4';

function App({ appLoading, isAuthenticated, location, user, ...props }) {
  // const locations = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_MANAGER);
  }, []);

  // const history = useHistory();

  const hasLmsAccess = useMemo(() => {
    return user?.lmsmonthly === true || user?.lmsannual === true;
  }, [user]);

  const hasOutreachAccess = useMemo(() => {
    if (!user?.outreachAnnual && !user?.outreachMonthly && !hasLmsAccess) return true;
    return user?.outreachAnnual === true || user?.outreachMonthly === true;
  }, [user]);

  const isUser = ['standard']?.includes(user?.acl?.accessLevel?.toLowerCase());

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname?.split('/')[1] === 'admin' && user?.id && isUser) {
        if (!hasOutreachAccess && !location.pathname.includes('/billing')) {
          // window.location.replace('/lms');
          window.location.replace(process.env.REACT_APP_LMS_URL);
        } else {
          window.location.replace('/');
        }
      }

      if (hasLmsAccess) {
        const allowedPaths = [
          '/profile',
          '/admin/course',
          '/admin/users',
          '/admin/notes',
          '/admin/teams',
          '/admin/offices',
          '/admin/roleplay',
          '/admin/users/:id',
          '/admin/user/:id/:tab',
          // 'admin/competitions',
        ];
        const currentPathname = location.pathname;
        let checkAllowedWithParams = allowedPaths.some((path) => {
          const regex = new RegExp('^' + path.replace(/:[^\s/]+/g, '([^/]+)') + '$');
          return regex.test(currentPathname);
        });

        let allow = allowedPaths.reduce((res, path) => {
          if (location.pathname.includes(path)) {
            res = true;
          }
          return res;
        }, false);

        let isLMSAllowed = allow || checkAllowedWithParams;
        if (hasOutreachAccess === true) isLMSAllowed = true;

        if (
          !location.pathname.includes('/auth/setup/password') &&
          !location.pathname.includes('/lms') &&
          !location.pathname.includes('/billing') &&
          !isLMSAllowed
        ) {
          // history.replace('/lms');
          // window.location.replace('/lms');
          window.location.replace(process.env.REACT_APP_LMS_URL);
        }
      }
      if (!hasLmsAccess) {
        if (location.pathname.toLowerCase().includes('/lms')) {
          // history.replace('/tasks');
          window.location.replace('/tasks');
        }
      }
    }
  }, [location.pathname, hasLmsAccess, hasOutreachAccess, isAuthenticated]);

  useEffect(() => {
    props.restoreSession(false, true);
  }, []);

  const isDark = location.pathname === '/auth/setup/password' || location.pathname.includes('/lms');

  // const isloading =
  //   (location.pathname.includes('/lms') && !hasLmsAccess && hasOutreachAccess) ||
  //   (!location.pathname.includes('/lms') && hasLmsAccess && !hasOutreachAccess);

  if (appLoading) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={isDark ? darkTheme : theme}>
          <AppLoader />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={isDark ? darkTheme : theme}>
          <Toast>
            <Switch>
              {standAlonePages.some((path) => isPathMatched(location.pathname, path)) ? (
                <Router />
              ) : authPages.includes(location.pathname) ? (
                <AuthLayout {...props}>
                  <Router />
                </AuthLayout>
              ) : location.pathname?.split('/')[1] !== 'lms' ? (
                <AppLayout
                  {...props}
                  hasLmsAccess={hasLmsAccess}
                  hasOutreachAccess={hasOutreachAccess}
                >
                  <Router />
                </AppLayout>
              ) : (
                <LearningLayout
                  {...props}
                  hasLmsAccess={hasLmsAccess}
                  hasOutreachAccess={hasOutreachAccess}
                >
                  <Router />
                </LearningLayout>
              )}
            </Switch>
          </Toast>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  appLoading: state.app.appLoading,
  isAuthenticated: state.auth.isAuthenticated,
  location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
  restoreSession: (newUser) => dispatch(restoreSession(newUser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
