import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Drawer } from 'src/components/shared';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  ListItemIcon,
  Avatar,
  Stack,
} from '@mui/material';

import GlobalSearch from '../GlobalSearch';

import { fetchIcp, fetchVoices, logout } from 'src/modules/auth/actions/authActions';
import {
  openCloseSideBar,
  toggleAddContactForm,
  toggleAddSequenceForm,
  toggleCreateEmailForm,
  toggleAddTaskForm,
  toggleNotificationsDrawer,
} from 'src/modules/app/actions/appActions';
//
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import CalenderEvent from 'src/modules/calanderEvent';
import PageTitle from './PageTitle';

import { useIntercom } from 'react-use-intercom';

import images from 'src/config/images';

import useStyles from './styles';
import UpgradeSubscriptionModal from '../Sidebar/UpgradeSubscriptionModal';
import { trialSubscriptionPlansPriceId } from 'src/config';
import AppMenu from '../AppMenu';

const AppHeader = ({
  auth: { isAuthenticated, user },
  logout,
  hasLmsAccess,
  hasOutreachAccess,
  showSidebar,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { boot, show } = useIntercom();
  const name = user ? `${user.fname || ''}` : '';

  const [userNameMenuElement, setUserNameMenuElement] = useState(null);
  const [plusMenu, setPlusMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState({ open: false });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { sideBarIsOpen } = useSelector((state) => state.app);

  useEffect(() => {
    if (!props.icp?.length) {
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
    }
    if (!props.voices?.length) {
      props.fetchVoices(props.voices.paging, props.voices.filters, props.voices.sort);
    }
  }, []);

  const togglePlusMenu = (e) => {
    if (plusMenu) {
      setPlusMenu(false);
    } else {
      setPlusMenu(e.currentTarget);
    }
  };

  const matchActive = (pathname) => location.pathname.startsWith(pathname);
  const userNameId = 'user-name-menu-id';

  const handleUserNameMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setUserNameMenuElement(event.currentTarget);
  };

  const isUserOnTrial = (user) => {
    if (!user?.tenants?.length || !user?.tenants[0]?.subscription) {
      return false;
    }
    const subscriptionData = user.tenants[0].subscription;
    const hasActiveStatus = Object.values(subscriptionData).filter(
      (item) =>
        item &&
        Object.keys(item).length > 0 &&
        item?.status === 'active' &&
        !trialSubscriptionPlansPriceId.includes(item?.item_price_id || item?.entity_id),
    );
    return hasActiveStatus?.length > 0;
  };

  const showUpgradePlan = useMemo(() => isUserOnTrial(user), [user]);

  const handleMenuClose = () => {
    setUserNameMenuElement(null);
  };

  const onPlusMenuAction = (action) => {
    switch (action) {
      case 'addContact':
        dispatch(toggleAddContactForm());
        break;
      case 'addSequence':
        dispatch(toggleAddSequenceForm());
        break;

      case 'emailTemplate':
        history.push('/templates/email');
        break;

      case 'linkedinTemplate':
        history.push('/templates/linkedin');
        break;

      case 'addTemplate':
        // case 'emailTemplate':
        // case 'linkedinTemplate':
        dispatch(toggleCreateEmailForm(action));
        break;

      case 'addTask':
        dispatch(toggleAddTaskForm());
        break;

      default:
        break;
    }
    setPlusMenu(false);
  };

  // logout user
  const handleLogout = (event) => {
    // setTenantHash();
    handleMenuClose();
    dispatch(logout(event));
  };

  const setActiveClass = (url) => (matchActive(url) ? 'active' : '');

  let locations = useLocation();
  ``;
  const path = locations.pathname;
  const isDashboard = path === '/dashboard' && false;
  const isBoards = path === '/boards';
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch(openCloseSideBar());
  };
  const toggleNotifications = () => {
    dispatch(toggleNotificationsDrawer());
  };

  const dateEvents = (
    <Drawer
      title={'Calender Events'}
      open={drawerOpen.open}
      onClose={() => {
        setDrawerOpen({ open: false });
      }}
    >
      <CalenderEvent />
    </Drawer>
  );

  const hasAdminAccess = ['teamlead', 'admin', 'manager']?.includes(
    user?.acl?.accessLevel?.toLowerCase(),
  );

  const authLinks = (
    <Toolbar variant="dense" className={classes.toolbarStyle}>
      {showSidebar ? (
        <Box display="flex" alignItems="center" flex={1}>
          {!isDashboard && (
            <IconButton onClick={toggleSidebar} style={{ outline: 'none' }} size="medium">
              {sideBarIsOpen ? (
                <img
                  src={images.app.sideBarIcon}
                  alt=""
                  style={{ margin: 'auto' }}
                  height="18px"
                  width="18px"
                />
              ) : (
                <img
                  src={images.app.sideBarIcon}
                  alt=""
                  style={{ margin: 'auto' }}
                  height="18px"
                  width="18px"
                />
              )}
            </IconButton>
          )}
          <PageTitle />
        </Box>
      ) : (
        <img src={images.app.logo} style={{ height: '30px' }} />
      )}

      <Box flex={1}>
        <GlobalSearch />
      </Box>

      <Box component="div" display="flex" alignItems="center" flex={1} justifyContent="flex-end">
        {showSidebar && (
          <>
            <Box>
              <Tooltip title="Help & Support">
                <Button
                  iconButton
                  onClick={async () => {
                    try {
                      await boot();
                      await show();
                    } catch (error) {}
                  }}
                  size="medium"
                >
                  <HelpIcon />
                </Button>
              </Tooltip>

              {/*<PinnedBoards />*/}

              <Button
                iconButton
                onClick={(e) => {
                  setDrawerOpen({ open: true });
                }}
                className={classes.plusMenu}
                size="medium"
              >
                <CalendarMonthIcon />
              </Button>
            </Box>
            {dateEvents}

            <Button
              iconButton
              onClick={(e) => {
                togglePlusMenu(e);
              }}
              className={classes.plusMenu}
              size="medium"
            >
              <AddCircleOutlineIcon />
            </Button>

            <Button
              iconButton
              onClick={(e) => {
                history.push('/outreachvideolibrary');
                // history.push('/onboarding');
              }}
              size="medium"
            >
              <RocketLaunchIcon />
            </Button>

            {/* <Button iconButton onClick={toggleNotifications} size="medium">
              <NotificationsNoneOutlinedIcon />
            </Button> */}
            {/*<Notifications />*/}
          </>
        )}

        <Menu
          anchorEl={plusMenu}
          id="plus-menu-icon"
          keepMounted
          open={Boolean(plusMenu)}
          onClose={togglePlusMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.menuPaper,
          }}
        >
          <MenuItem onClick={() => onPlusMenuAction('addContact')}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <Typography>Contact</Typography>
          </MenuItem>
          <MenuItem onClick={() => onPlusMenuAction('addSequence')}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <Typography>Sequence</Typography>
          </MenuItem>

          <MenuItem onClick={() => onPlusMenuAction('addTask')}>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <Typography>Task</Typography>
          </MenuItem>
          <MenuItem onClick={() => onPlusMenuAction('emailTemplate')}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <Typography>Email Template</Typography>
          </MenuItem>
          <MenuItem onClick={() => onPlusMenuAction('linkedinTemplate')}>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <Typography>LinkedIn Template</Typography>
          </MenuItem>
        </Menu>

        {/*<Tooltip title={name || ''} aria-label="add">*/}
        {/*  <Button*/}
        {/*    onClick={handleUserNameMenuOpen}*/}
        {/*    endIcon={<ExpandMoreIcon />}*/}
        {/*    className={classes.userButton}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      className={`${classes.typography} ${classes.userName}`}*/}
        {/*      aria-controls={userNameId}*/}
        {/*      aria-haspopup="true"*/}
        {/*      component="div"*/}
        {/*    >*/}
        {/*      {name || ''}*/}
        {/*    </Typography>*/}
        {/*  </Button>*/}
        {/*</Tooltip>*/}

        <Box onClick={handleUserNameMenuOpen}>
          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            sx={{
              cursor: 'pointer',
              border: '1px solid #0000001f',
              boxShadow: !Boolean(userNameMenuElement) ? 'none' : '0px 4px 4px 0px #0000001f',
              padding: '4px 10px',
              borderRadius: '7px',
            }}
          >
            <img src={images.icons.appIcon} alt="app icon" />
            <Avatar className={classes.menuAvtar}>{name.charAt(0)}</Avatar>
          </Box>
        </Box>
        <AppMenu
          handleMenuClose={handleMenuClose}
          userNameMenuElement={userNameMenuElement}
          userNameId={userNameId}
          hasLmsAccess={hasLmsAccess}
          hasOutreachAccess={hasOutreachAccess}
          showUpgradePlan={showUpgradePlan}
          user={user}
          handleLogout={handleLogout}
          setIsModalOpen={setIsModalOpen}
          {...props}
        />
      </Box>
      <UpgradeSubscriptionModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </Toolbar>
  );

  return path.includes('sequence/steps') ? null : isAuthenticated ? (
    <AppBar
      className={`${
        !showSidebar
          ? classes.noSidebar
          : isDashboard
          ? classes.rootDashboard
          : isBoards
          ? classes.rootBoards
          : classes.root
      } ${!sideBarIsOpen ? classes.rootCollapsed : ''}`}
      color="inherit"
      position="fixed"
    >
      {authLinks}
    </AppBar>
  ) : null;
};

AppHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  icp: state.auth?.icp,
  voices: state.auth?.voices,
});

const mapDispatchToProps = (dispatch) => ({
  fetchICP: (paging, filters, sort) => dispatch(fetchIcp(paging, filters, sort)),
  logout: () => dispatch(logout()),
  fetchVoices: (paging, filters, sort) => dispatch(fetchVoices(paging, filters, sort)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
