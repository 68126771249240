import React, { useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useGuideModalStyles } from './styles';
import { CustomGatedFooterContent, CustomGatedMainContent } from '../styles';
import { useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import images from 'src/config/images';
import GatedModal from '../GatedModal';
import { Button } from 'src/components/shared';
import cookieUtils from 'src/utils/cookieUtils';
import { getUsers } from 'src/modules/auth/api/authApis';

const InvitedUserModal = () => {
  const router = useHistory();
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const guideModalStyle = useGuideModalStyles();
  const isShowInviteUserRedirectionModal = cookieUtils.get('SHOW_INVITE_TEAM_REDIRECTION_MODAL');
  const [totalUsers, setTotalUsers] = useState(null);
  const [showInviteUserModal, setShowInviteUserModal] = useState(true);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchTotalUsers();
  }, []);

  const fetchTotalUsers = async () => {
    try {
      setIsFetching(true);
      const users = await getUsers();
      setTotalUsers(users?.total?.value ?? 0);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      console.error(e);
    }
  };

  const isShowInviteUserModal = useMemo(
    () =>
      !isFetching &&
      showInviteUserModal &&
      totalUsers < 2 &&
      !isShowInviteUserRedirectionModal &&
      user?.outreachOnboarding?.inviteTeamRedirectionModal &&
      user?.isAdmin,
    [user, totalUsers, showInviteUserModal],
  );

  const redirectOnInviteUserScreen = () => {
    cookieUtils.unset('SHOW_INVITE_TEAM_REDIRECTION_MODAL');
    router.replace('/admin/users');
    onClose();
  };

  const onClose = () => {
    cookieUtils.set('SHOW_INVITE_TEAM_REDIRECTION_MODAL', true, 2);
    setShowInviteUserModal(false);
  };

  return (
    <GatedModal
      open={isShowInviteUserModal}
      onCloseModal={onClose}
      className={guideModalStyle.root}
      renderFooter={
        <CustomGatedFooterContent>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap={2}>
            <Button variant="text" color="primary" onClick={onClose}>
              <Typography fontSize="inherit" color={theme.palette.text.grey} onClick={onClose}>
                Later
              </Typography>
            </Button>
            <Button variant="contained" color="secondary" onClick={redirectOnInviteUserScreen}>
              Invite Team
            </Button>
          </Stack>
        </CustomGatedFooterContent>
      }
      image={images.onboarding.inviteUser}
    >
      <CustomGatedMainContent>
        <Stack gap={2}>
          <Typography className="title" color={theme.palette.text.black1}>
            You’ve still got time to invite your team!
          </Typography>
          <Typography color={theme.palette.text.black1}>
            Invite unlimited team members to collaborate, explore powerful tools, and drive better
            results together!
          </Typography>
        </Stack>
      </CustomGatedMainContent>
    </GatedModal>
  );
};

export default InvitedUserModal;
